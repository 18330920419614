import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

function ContainerSpinner(props) {
    const { size } = props
    return (
        <Container>
            <CircularProgress size={size} />
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20%;
`
export default ContainerSpinner