import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
    },
    dropdown: {
        width: 140,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    icon: {
        fill: '#fff',
    },
    selectDiv: {
        margin: '0 10px',
        width: 150,
        position: 'relative',
    },

    apiVersion: {
        width: 150,
        position: 'relative',
    }
}));

const DocDropDown = (props) => {
    const { listedVersions, setCurrVersionSelection, currentVersion } = props
    const classes = useStyles()
    const [version, setVersion] = React.useState(currentVersion);
    const [selectedIndex, setSelectedIndex] = React.useState('');

    const handleChange = (event) => {
        setVersion(event.target.value)
        setCurrVersionSelection(event.target.value);
    }

    const handleMenuItemClick = (event, item) => {
        setSelectedIndex(localStorage.setItem('version', item));
        window.location.reload();
    }

    /* on default setting the selectedIndex */
    useEffect(() => {
        setSelectedIndex(localStorage.getItem('version'));
    }, [])

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <div className={classes.apiVersion}>
                <InputLabel id="demo-simple-select-outlined-label">
                    <Typography variant="inherit" style={{ color: '#3D9BE9' }}>
                        API Version
                </Typography>
                </InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={version}
                    className={classes.dropdown}
                    onChange={handleChange}
                    label="API Version"
                    style={{ color: '#fff' }}
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                        },
                    }}
                >
                    {
                        listedVersions && Array.isArray(listedVersions) &&
                        listedVersions.map((item, idx) => {
                            return (
                                <MenuItem
                                    key={idx}
                                    value={item}
                                    selected={item === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, item)}> {item} </MenuItem>
                            )
                        })
                    }
                </Select>
            </div>
        </FormControl>
    )
}

export default DocDropDown