import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DataTableContainer from '../TableContainer/TableContainer'
import DownloadContainer from '../DownloadContainer/DownloadContainer'
import ImageContainer from '../ImageContainer/ImageContainer'
import CodeContainer from '../CodeContainer/CodeContainer'
import Divider from '@material-ui/core/Divider'
import SinglePageContentContainer from './SinglePageContentContainer'
import BaseContentContainer from './BaseContentContainer'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '55px 65px 65px',
        marginTop: '80px',
        marginLeft: '10px',
        minHeight: '80vh',
        marginRight: '1%',
        height: 'calc(100vh - 70px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    ccontainer: {
        margin: '5px',
        overflowX: 'hidden',
        width: '100%'
    },
    containerItems: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: '400',
        lineHeight: '1.334'
    },
    toolbar: theme.mixins.toolbar,
    paraHeader: {
        margin: '5px',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#3D9BE9'
    },
    para: {
        margin: '10px 0',
        fontStyle: 'normal',
        letterSpacing: '0.1px',
        fontSize: '14px',
    },
    leftContentWithoutCode: {
        width: '100%',
    },
    leftContent: {
        width: '44%',
        marginRight: '120px',
    },
    rightContent: {
        width: '45%',
    },
    sectionDemarcation: {
        height: '30px'
    }
}));

const ContentContainer = (props) => {
    const classes = useStyles()
    const { header, contents, tables, code, images, subHeader, ignoreSubMenus, isCode, singlePageRendering, hideSubMenusContent } = props.data
    const dataItems = {
        header: header,
        contents: contents, // this may contain => { content, images, code, tables }
        tables: tables,
        code: code,
        images: images,
        subHeader: subHeader, // this may contain => { header, contents, tables, code, images, subHeader }
        singlePageRendering: singlePageRendering,
        hideSubMenusContent: hideSubMenusContent,
        ignoreSubMenus: ignoreSubMenus,
        isCode: isCode
    }
    const divRef = useRef(null)

    useEffect(() => {
        if(divRef?.current?.firstChild)
            divRef.current.firstChild.scrollTop = 0
    
    },[header])

    return (
        <div ref={divRef}>
            {
                dataItems.singlePageRendering
                    ?
                    <SinglePageContentContainer data={props.data} />
                    :
                    (
                        dataItems.hideSubMenusContent ?
                            <BaseContentContainer data={props.data} />
                            :
                            <Grid className={classes.container} >
                                {
                                    Object.keys(dataItems).map((key, idx) => {
                                        return (
                                            <div>
                                                {key === 'header' && <Typography key={idx} className={classes.heading} weight={"bold"} variant={"h2"} gutterBottom
                                                    style={{ padding: '3px', borderBottom: '1px solid #C9CED2', width: '100%' }}>
                                                    {dataItems[key]}
                                                </Typography>}
                                                {
                                                    key === 'contents' && Array.isArray(dataItems[key]) &&
                                                    dataItems[key].map((dataItem) => {
                                                        return (
                                                            <>
                                                                {
                                                                    dataItem.url
                                                                        ?
                                                                        <DownloadContainer key={idx} item={dataItem} />
                                                                        :
                                                                        <>
                                                                            {
                                                                                dataItem.content && Array.isArray(dataItem.content) &&
                                                                                dataItem.content.map((it, idx) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography key={idx} className={classes.para} indent={"small"}>
                                                                                                {it}
                                                                                            </Typography>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                dataItem.images &&
                                                                                dataItem.images.map((item) => {
                                                                                    return (
                                                                                        <>
                                                                                            <ImageContainer key={idx} image={item} />
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                dataItem.tables &&
                                                                                <DataTableContainer key={idx} tableData={dataItem.tables} />
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                                {
                                                    key === 'subHeader' && Array.isArray(dataItems[key]) &&
                                                    dataItems[key].map((dataItem, idx) => {
                                                        return (
                                                            <>
                                                                {
                                                                    dataItem.header &&
                                                                    <Typography key={idx} className={classes.paraHeader} variant={"overline"} weight={"bold"} gutterBottom>
                                                                        {dataItem.header}
                                                                    </Typography>
                                                                }
                                                                <Grid>
                                                                    <Grid item sm={12} container spacing={0} className={'pages ' + classes.ccontainer}>
                                                                        <div className={(dataItem.isCode ? classes.leftContent : classes.leftContentWithoutCode)} sm={6}>
                                                                            {
                                                                                dataItem.contents && Array.isArray(dataItem.contents) &&
                                                                                dataItem.contents.map((item, indx) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                item.content && Array.isArray(item.content) &&
                                                                                                item.content.map((it, content_index) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Typography key={content_index} className={classes.para} indent={"small"}>
                                                                                                                {it}
                                                                                                            </Typography>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                item.images &&
                                                                                                item.images.map((it, image_index) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <ImageContainer key={image_index} image={it} />
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                item.tables && <DataTableContainer key={indx} tableData={item.tables} />
                                                                                            }

                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <div className={classes.rightContent} sm={6}>
                                                                            {
                                                                                dataItem.isCode && dataItem.contents && Array.isArray(dataItem.contents) &&
                                                                                dataItem.contents.map((item, indx) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                item.code &&
                                                                                                <CodeContainer sectionClass="noClass" key={item} data={dataItem.contents} />
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                    <Divider />
                                                                    <div className={classes.sectionDemarcation} />
                                                                </Grid>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </Grid>
                    )

            }
        </div>
    )
}

export default ContentContainer