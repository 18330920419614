import React, { useEffect } from 'react'
import axios from 'axios'
import CssBaseline from '@material-ui/core/CssBaseline'
import ContainerSpinner from '../components/ContainerSpinner/index'
import Sidebar from '../components/AppNav/Sidebar/Sidebar'
import RespAppBar from '../components/AppNav/RespAppBar/RespAppBar'
import ContentProvider from './ContentContainer/ContentProvider'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import SearchContainer from './ContentContainer/SearchContainer/SearchContainer'

const SLASH = '/'
const DIR_NAME = './assets/data/version/'
const DATA_JSON = 'CAPI_DocForWeb.json'
const OVERVIEW_CONTENT = 'Initial_Content.json'
const VERSION_FILE = 'Versions.json'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Prompt',
    ].join(','),
  },
  palette: {
    background: {
      default: '#fff'
    }
  },
  pages: {
    border: '1px solid #000'
  }
});

const App = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [allJson, setAllJson] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [dataToShow, setDataToShow] = React.useState({})
  const [versions, setVersions] = React.useState([])
  const [currVersion, setCurrVersion] = React.useState(localStorage.getItem('version'))
  const [versionFolder, setVersionFolder] = React.useState('version_1.0')
  const [activeTab, setActiveTab] = React.useState(null)
  const [showHideContent, setShowHideContent] = React.useState(true)
  const [showHideSearch, setShowHideSearch] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const updateCurrentSelection = (item) => {
    setDataToShow(item)
    if (item.header) {
      setActiveTab(item.header)
    }
  }

  const setCurrVersionSelection = (val) => {
    setLoading(true);
    let versionCatalogue = [
      axios.get(process.env.PUBLIC_URL + DIR_NAME + VERSION_FILE)
    ]
    Promise.all(versionCatalogue).then(
      resp => {
        resp[0].data.versions.map((ver) => {
          if (val === ver.version) {
            setVersionFolder(ver.contentFolder)
            setCurrVersion(ver.version)
          }
        })
        setLoading(false)
      },
      err => {
        console.log('app error =', err)
      })
  }

  /* setting the version folder and current version based on current version change */
  useEffect(() => {
    setCurrVersion(localStorage.getItem('version'));
    let versionCatalogue = [
      axios.get(process.env.PUBLIC_URL + DIR_NAME + VERSION_FILE)
    ]
    Promise.all(versionCatalogue).then(
      resp => {
        var allversions = []
        resp[0].data.versions.map((ver) => {
          allversions.push(ver.version)
          if (currVersion === ver.version) {
            setVersionFolder(ver.contentFolder)
            setCurrVersion(ver.version)
          }

          if (currVersion === null || currVersion === undefined) {
            if (ver.latest) {
              setVersionFolder(ver.contentFolder);
              setCurrVersion(ver.version);
              localStorage.setItem('version', ver.version);
            }
          }
          return allversions;
        })
        setVersions(allversions);

      },
      err => {
        setVersions([])
      })
  }, [currVersion])


  //for search button clicked
  useEffect(() => {
    let searchClicked = JSON.parse(localStorage.getItem('searchClicked'));
    if (searchClicked === true && searchClicked !== null) {
      setShowHideContent(false);
      setShowHideSearch(true);
      setDataToShow(JSON.parse(localStorage.getItem('searchData')));
      setSearchText(localStorage.getItem('searchText'));
    } else {
      let initial_content = [
        axios.get(process.env.PUBLIC_URL + DIR_NAME + OVERVIEW_CONTENT)
      ]
      Promise.all(initial_content).then(response => {
        setDataToShow(response[0].data);
      }, err => {
        console.log('error == ', err)
      })
    }
  }, []);

  /* fetching the data based on version folder and current version change */
  useEffect(() => {
    let versionCatalogue = [
      axios.get(process.env.PUBLIC_URL + DIR_NAME + VERSION_FILE)
    ]
    Promise.all(versionCatalogue).then(
      resp => {
        resp[0].data.versions.map((ver) => {
          if (currVersion === ver.version) {
            setVersionFolder(ver.contentFolder)
          }
        })
      },
      err => {
        setVersions([])
      })

    let promises = [
      axios.get(DIR_NAME + versionFolder + SLASH + DATA_JSON)
    ]
    Promise.all(promises).then(response => {
      setAllJson(
        response.map((item) => {
          return item.data
        })
      )
      setLoading(false);
    },
      err => {
        setAllJson([])
      })
  }, [currVersion, versionFolder]);

  /*Use Effect for Search butotn click */
  useEffect(() => {
    setInterval(() => {
      let searchClicked = JSON.parse(localStorage.getItem('searchClicked'));
      if (searchClicked === true && searchClicked !== null) {
        setShowHideContent(false);
        setShowHideSearch(true);
        setDataToShow(JSON.parse(localStorage.getItem('searchData')));
        setSearchText(localStorage.getItem('searchText'));
      } else {
        setShowHideContent(true);
        setShowHideSearch(false);
      }
    }, 1000);
  }, []);

  /* Use Effect will work on card click */
  const searchData = JSON.parse(localStorage.getItem('searchData'));
  useEffect(() => {
    let cardDetails = localStorage.getItem('cardData');
    if (cardDetails && searchData && cardDetails !== null) {
      for (let i = 0; i < searchData.length; i++) {
        if (searchData[i].header === cardDetails) {
          setDataToShow(searchData[i]);
        }
      }
      setSearchText(localStorage.getItem('searchText'));
      setShowHideContent(true);
      setShowHideSearch(false);
      localStorage.setItem('searchClicked', false);
    }
  }, []);

  return (
    <>
      <CssBaseline />
      {
        !loading
          ?
          <ThemeProvider key={theme} theme={theme}>
            <CssBaseline />
            <RespAppBar handleDrawerToggle={handleDrawerToggle} listedVersions={versions} currentVersion={currVersion} setCurrVersionSelection={setCurrVersionSelection} />
            <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} data={allJson} updateMethod={updateCurrentSelection} activeTab={activeTab} />
            {showHideContent && <ContentProvider data={dataToShow} />}
            {showHideSearch && <SearchContainer searchText={searchText} />}
          </ThemeProvider>
          :
          <ContainerSpinner />
      }

    </>
  )
}

export default App;