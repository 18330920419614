import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CodeSnippetsContainer from './CodeSnippetsContainer'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '65px 70px 0px 0px',
        marginTop: '80px',
        marginLeft: '10px',
        marginRight: '1%',
        height: 'calc(100vh - 70px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    ccontainer: {
        padding: '20px 5px',
        marginLeft: '3px',
        marginRight: '1%',
        height: 'calc(100vh - 70px)',
        overflowX: 'hidden',
    },
    toolbar: theme.mixins.toolbar,
    sectionDemarcation: {
        height: '30px'
    },
    codeContentDemarcation: {
        height: '10px'
    },
    para: {
        margin: '0 10px',
        fontStyle: 'normal',
        letterSpacing: '0.1px',
        fontSize: '14px',
    }
}));

const CodeContainer = (props) => {
    const classes = useStyles()
    const contents = props.data
    return (
        <Grid className={(props.sectionClass ? '' : classes.container)}>
            {
                contents && Array.isArray(contents) &&
                contents.map((item, idx) => {
                    if (item.code && Array.isArray(item.code)) {
                        return (
                            item.code.map((c) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <div className={classes.codeContentDemarcation} />
                                        {
                                            c.content && Array.isArray(c.content) &&
                                            c.content.map((it, idx) => {
                                                return (
                                                    <Typography className={classes.para} indent={"small"} key={idx}>
                                                        {it}
                                                    </Typography>
                                                )
                                            })
                                        }
                                        <div className={classes.codeContentDemarcation} />
                                        <CodeSnippetsContainer codeItems={c} />
                                    </React.Fragment>
                                )
                            })
                        )
                    }
                    return null
                })
            }
        </Grid>
    )
}
export default CodeContainer
