import React, { useState } from "react"
import axios from 'axios'
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DocumentInfoCard from './DocumentInfoCard'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

const useStyles = makeStyles({
    headerStyle: {
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '1px',
        color: '1E1E71'
    },
    docStyle: {
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '1px',
        color: '#1E1E71'
    },
    scrollWrap: {
        maxHeight: '89vh',
        overflow: 'auto',
    },
    activeItem: {
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '1px',
        color: '#ffffff !important',
        backgroundColor: '#428bca',
        padding: '12px',
        borderRadius: '4px',
        width: '230px'
    },
    searchBox: {
        float: 'left',
        margin: '10px'
    },
    search: {
        width: '150px'
    },
    searchBtn: {
        width: '60px'
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        }
    }
})

const allHeaders = [
    "Introduction",
    "Interface Technical Details",
    "GNSS Related Messages",
    "Basic Steering and Guidance Messages",
    "Generic Parameter Feature",
    "Initialization Messages",
    "Shutdown Messages",
    "Diagnostic Messages",
    "System Configuration Summary Messages",
    "Wi-Fi Communication messages",
    "Generic System Messages",
    "NTRIP Related Messages",
    "CAPI Tunnel Related Messages",
    "Browser related Messages",
    "Four Wheel Steer Messages",
    "CAPI Message Definitions for Vehicle Creation and Calibration",
    "CAPI Message Definitions for Implement-Steer",
    "Supporting Information",
    "Browser Screens for Vehicle creation, calibration and more",
    "Firmware Upgrade",
    "Customer Screen recommendations",
    "Safety",
    "FAQS",
    "CAPI Code Samples"
]

function SidebarItem({ panel, depthStep = 10, depth = 0, expanded, item, updateCurrentSelection, activeTab, handleAccordionChange, accordionExpanded, ...rest }) {
    const classes = useStyles()
    const { data, document, header, subHeader, ignoreSubMenus } = item

    function onClick(e) {
        updateCurrentSelection(item)
        localStorage.removeItem('searchData')
        localStorage.removeItem('searchClicked')
        localStorage.removeItem('searchText')
    }

    let expandIcon
    if (Array.isArray(data) && data.length) {
        expandIcon = accordionExpanded ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)
    }

    let isActiveTab = false
    if (activeTab === header) {
        isActiveTab = true
    }

    return (
        <>
            <Accordion elevation={0} classes={{ root: classes.MuiAccordionroot }} id={`${header}`} expanded={accordionExpanded === `${header}`}
                onChange={handleAccordionChange(`${header}`)}
            >
                <AccordionSummary
                    expandIcon={expandIcon}
                    aria-controls={`${panel}d-content`}
                    id={`${panel}d-header`}
                    onClick={onClick}
                    button
                    dense
                    {...rest}
                    className={isActiveTab ? classes.activeColor : ''}
                >
                    <div className="manu-item"> {/*style={{ paddingLeft: depth * depthStep }}*/}
                        <Typography className={document ? classes.docStyle : isActiveTab ? classes.activeItem : classes.headerStyle} title={document ? document : header}>
                            {document ? document : header}
                        </Typography>
                    </div>
                </AccordionSummary>
                {
                    !ignoreSubMenus &&
                    <AccordionDetails aria-controls={`${panel}d-content`}>
                        {
                            data && Array.isArray(data) ?
                                (
                                    <List disablePadding dense
                                        aria-controls={`${panel}d-content`}
                                        id={`${panel}d-header`}
                                    >
                                        {data.map((subItem, index) => (
                                            <React.Fragment key={`${subItem.header}${index}`}>
                                                <SidebarItem
                                                    depth={depth + 1}
                                                    depthStep={depthStep}
                                                    item={subItem}
                                                    updateCurrentSelection={updateCurrentSelection}
                                                    activeTab={activeTab}
                                                    handleAccordionChange={handleAccordionChange}
                                                    accordionExpanded={accordionExpanded}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                ) : null
                        }
                        {
                            subHeader && Array.isArray(subHeader) ?
                                (
                                    <List disablePadding dense>
                                        {subHeader.map((subItem, index) => (
                                            <React.Fragment key={`${subItem.header}${index}`}>
                                                <SidebarItem
                                                    depth={depth + 1}
                                                    depthStep={depthStep}
                                                    item={subItem}
                                                    updateCurrentSelection={updateCurrentSelection}
                                                    activeTab={activeTab}
                                                    handleAccordionChange={handleAccordionChange}
                                                    accordionExpanded={accordionExpanded}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                ) : null
                        }
                    </AccordionDetails>
                }
            </Accordion>
        </>
    );
}

const RecursiveSideBarDataProvider = (props) => {
    const classes = useStyles()
    const [search, setSearch] = useState('');
    const [allJson, setAllJson] = React.useState([])
    const { sidebarItems, depthStep, depth, updateCurrentSelection, activeTab } = props
    let searchData = [];
    const [accordionExpanded, setAccordionExpanded] = React.useState(false)

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        if (allHeaders.includes(event.target.title))
            setAccordionExpanded(isExpanded ? panel : false)
    }

    const handleChange = (e) => {
        setSearch(e.target.value);
    }

    function searchItem(item) {
        Object.keys(item).forEach(key => {
            //search in header      
            if (item[key].header && (item[key].header.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                searchData.push(item[key]);
            } else if (item[key].subHeader) {
                //search in subHeader
                item[key].subHeader.map((val) => {
                    if (val.header.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                        searchData.push(item[key]);
                    }
                    //search in subHeader subHeader
                    if (val.subHeader !== undefined) {
                        val.subHeader.map((val2) => {
                            if (val2.header.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                                searchData.push(val);
                            }
                        })
                    }
                })
            } else if (item[key].contents && item[key].contents[0].content) {
                //search in contents       
                item[key].contents[0].content.map(val => {
                    if (val.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                        searchData.push(item[key]);
                    }
                })
            }
        })
        searchData = searchData.filter(function (a) {
            var key = a.header;
            if (!this[key]) {
                this[key] = true;
                return true;
            }
        }, Object.create(null));
        //console.log("searchData",searchData);
        localStorage.setItem('searchData', JSON.stringify(searchData));
        localStorage.setItem('searchClicked', true);
        window.location.reload();
    }

    function handleSearch(e) {
        e.preventDefault();
        const SLASH = '/'
        const DIR_NAME = './assets/data/version/'
        const DATA_JSON = 'CAPI_DocForWeb.json'
        const versionFolder = 'version_1.0'
        let promises = [
            axios.get(DIR_NAME + versionFolder + SLASH + DATA_JSON)
        ]
        Promise.all(promises).then(
            response => {
                setAllJson(
                    response.forEach(item => {
                        searchItem(item.data.data);
                    })
                )
            },
            err => {
                setAllJson([])
            })
        //save searchData in localStorage          
        localStorage.setItem('searchData', JSON.stringify(searchData));
        localStorage.setItem('searchText', search.toLowerCase());
        localStorage.removeItem('cardData');
    }

    return (
        <>
            <DocumentInfoCard />
            <Divider />
            <form onSubmit={handleSearch}>
                <div className={classes.searchBox}>
                    <input type="text" value={search} className={classes.search} onChange={handleChange} placeholder="Search..." />
                    <input type="submit" className={classes.searchBtn} value="Search" />
                </div>
            </form>
            <List disablePadding dense className={classes.scrollWrap}>
                {
                    sidebarItems[0].data.map((dataItem, index) => {
                        return (
                            <React.Fragment key={`${index}`}>
                                <SidebarItem
                                    panel={`panel-${index}`}
                                    depthStep={depthStep}
                                    depth={depth}
                                    expanded={`panel-${index}`}
                                    item={dataItem}
                                    updateCurrentSelection={updateCurrentSelection}
                                    activeTab={activeTab}
                                    handleAccordionChange={handleAccordionChange}
                                    accordionExpanded={accordionExpanded}
                                />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </>
    )
}

export default RecursiveSideBarDataProvider