import React from "react";

const Pagination = ({postsPerPage, totalPosts, paginate, currentPage}) => {
  const pageNumbers = [];
 
  for(let i=1; i<= Math.ceil(totalPosts / postsPerPage); i++){
    pageNumbers.push(i);

  }
  return (
    <nav>
      <ul className="pagination">
        { 
          pageNumbers.map((number,index) => (<li key={number} className={`page-item ${index+1 === currentPage ? 
          "active" : null}`}>
          <span onClick={() => paginate(number)} className="page-link">
            {number}
          </span>
          </li>
          ))}
      </ul>
    </nav>
  )
}

export default Pagination;