import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ContentContainer from '../ContentContainer/ContentContainer/ContentContainer'
import CodeContainer from '../ContentContainer/CodeContainer/CodeContainer'
// import ContentContainerProvider from '../ContentContainer/ContentContainer/ContentContainerProvider' 

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 5px',
        marginTop: '40px',
        marginLeft: '240px',
        width: 'calc(100% - 240px)',
        minHeight: 0,
        marginRight: '1%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
    },
    containerItems: {
        flexGrow: 1,
        overflow: 'hidden',
    }
}));

const ContentProvider = (props) => {
    const classes = useStyles()
    var xSize = 12
    let { isCode, contents } = props.data
    
    if (isCode) xSize = 6
    return (
        <>
            <Grid container spacing={0} className={'pages ' + classes.container}>
                {
                    isCode
                        ?
                        <>
                            <Grid item sm={xSize} className={classes.containerItems} >
                                <ContentContainer key={props.data} data={props.data} />
                            </Grid>
                            <Grid item sm={xSize} className={classes.containerItems} >
                                <CodeContainer key={contents} data={contents} />
                            </Grid>
                        </>
                        :
                        <Grid item sm={xSize} className={classes.containerItems} >
                            <ContentContainer key={props.data} data={props.data} />
                        </Grid>
                }
            </Grid>
        </>
    )
}

export default ContentProvider