import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Pagination from './Pagination';
import './Pagination.css';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
    },
    container: {
        height: 'calc(100vh - 70px)',
        minHeight: '80vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: '20px 5px',
        marginTop: '150px',
        marginLeft: '240px',
        width: 'calc(100% - 250px)',
        marginRight: '1%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
    },
    card: {
        width: "95%",
        height: "300px",
        cursor: "pointer",
        margin: "10px",
        transition: "0.3s",
        verticalAlign: "top",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        }
    },
    content: {
        overflowY: "scroll",
        height: "300px",
        textAlign: "left",
        padding: '10px',
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: '400',
        lineHeight: '1.334'
    },
    toolbar: theme.mixins.toolbar,
    paraHeader: {
        margin: '5px',
        fontStyle: 'italic',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#8181F7',
        width: '100%',
    },
    para: {
        margin: '10px',
        fontStyle: 'normal',
        letterSpacing: '0.1px',
        fontSize: '14px',
    },
    search: {
        color: 'gray',
        fontSize: '1.75rem',
    },
    pages: {
        display: 'none',
    },
    containerItems: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    noResults: {
        margin: '0 auto',
        fontStyle: 'normal',
        letterSpacing: '0.1px',
        fontSize: '14px',
    },
}));

const SearchContainer = (props) => {
    const classes = useStyles();
    const [searchData, setSearchData] = useState(JSON.parse(localStorage.getItem('searchData')));
    const searchText = props.searchText;
    let [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 5;

    const getData = (currentPage) => {
        if (data && data.length > 0) {
            const indexOfLastPost = currentPage * postsPerPage;
            const indexOfFirstPost = indexOfLastPost - postsPerPage;
            const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
            const postData = currentPosts.map((dataItem, key) => {
                return (
                    <div className={classes.wrapper}>
                        <Card className={classes.card} onClick={() => handleCard(dataItem.header)}>
                            <CardContent className={classes.content}>
                                <Typography className={classes.heading} weight={"bold"} variant={"h2"} gutterBottom style={{ padding: '3px', borderBottom: '1px solid #C9CED2', width: '100%' }}> {dataItem.header} </Typography>
                                {
                                    dataItem.contents && dataItem.contents.map((item) => {
                                        return (
                                            <>
                                                {
                                                    item.content && item.content.map((it) => {
                                                        return (
                                                            <Typography className={classes.para} indent={"small"}>
                                                                {it}
                                                            </Typography>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    })
                                }
                                {
                                    dataItem.subHeader &&
                                    dataItem.subHeader.map((dataItem) => {
                                        return (
                                            <>
                                                {
                                                    dataItem.header &&
                                                    <Typography className={classes.paraHeader} variant={"overline"} weight={"bold"} gutterBottom>
                                                        {dataItem.header}
                                                    </Typography>
                                                }
                                                {
                                                    dataItem.contents && Array.isArray(dataItem.contents) &&
                                                    dataItem.contents.map((item) => {
                                                        return (
                                                            <>
                                                                {
                                                                    item.content && Array.isArray(item.content) &&
                                                                    item.content.map((it) => {

                                                                        return (
                                                                            <Typography className={classes.para} indent={"small"}>
                                                                                {it}
                                                                            </Typography>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    })
                                }
                            </CardContent>
                        </Card>
                    </div>
                )
            })
            setData(postData);
        } else {
            setData(<p className={classes.noResults}>No results found!</p>);
        }
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSearchData(JSON.parse(localStorage.getItem('searchData')));
        data = searchData;
        getData(currentPage);
    }

    useEffect(() => {
        setSearchData(JSON.parse(localStorage.getItem('searchData')));
        data = searchData;
        getData(currentPage);
    }, [currentPage]);

    const handleCard = (cardData) => {
        //setting card data into localStorage       
        localStorage.setItem('cardData', cardData);
        window.location.reload();
    }

    return (
        <>
            <Grid container spacing={0} className={classes.container}>
                <Typography className={classes.search} weight={"bold"} variant={"h2"} style={{ height: '50px', borderBottom: '1px solid #C9CED2', width: '100%' }}>Search: {searchText}</Typography>
                {data}
                <Pagination postsPerPage={postsPerPage} totalPosts={searchData.length} paginate={paginate} currentPage={currentPage} />
            </Grid>
        </>
    )
}

export default SearchContainer;