import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiAppbar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import DocDropDown from './DocDropDown'
import Grid from '@material-ui/core/Grid'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    appBar: {
        background: 'linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%)',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        }
    }
}));

const RespAppBar = (props) => {
    const { handleDrawerToggle, listedVersions, setCurrVersionSelection, currentVersion } = props
    const classes = useStyles()
    return (
        <Grid container spacing={10}>
            <MuiAppbar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Grid item>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <DocDropDown listedVersions={listedVersions} setCurrVersionSelection={setCurrVersionSelection} currentVersion={currentVersion} />
                    </Grid>
                </Toolbar>
            </MuiAppbar>
        </Grid>
    )
}

export default RespAppBar