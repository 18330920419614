import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DataTableContainer from '../TableContainer/TableContainer'
import ImageContainer from '../ImageContainer/ImageContainer'
import DownloadContainer from '../DownloadContainer/DownloadContainer'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '55px 65px 65px',
        marginTop: '80px',
        marginLeft: '10px',
        minHeight: '80vh',
        marginRight: '1%',
        height: 'calc(100vh - 70px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: '400',
        lineHeight: '1.334'
    },
    toolbar: theme.mixins.toolbar,
    para: {
        margin: '10px 0',
        fontStyle: 'normal',
        letterSpacing: '0.1px',
        fontSize: '14px',
    }
}));

const BaseContentContainer = (props) => {
    const classes = useStyles()
    const { header, contents, tables, code, images, subHeader, isCode } = props.data
    const dataItems = {
        header: header,
        contents: contents, // this may contain => { content, images, code, tables }
        tables: tables,
        code: code,
        images: images,
        subHeader: subHeader, // this may contain => { header, contents, tables, code, images, subHeader }
        isCode: isCode
    }

    return (
        <Grid className={classes.container}>
            {
                Object.keys(dataItems).map((key, idx) => {
                    return (
                        <>
                            { key === 'header' && <Typography key={idx} className={classes.heading} weight={"bold"} variant={"h2"} gutterBottom
                                style={{ padding: '3px', borderBottom: '1px solid #C9CED2', width: '100%' }}>
                                {dataItems[key]}
                            </Typography>
                            }
                            {
                                key === 'contents' && Array.isArray(dataItems[key]) &&
                                dataItems[key].map((dataItem) => {
                                    return (
                                        <>
                                            {
                                                <>
                                                    {
                                                        dataItem.content && Array.isArray(dataItem.content) &&
                                                        dataItem.content.map((it, idx) => {
                                                            return (
                                                                <>
                                                                    <Typography key={idx} className={classes.para} indent={"small"}>
                                                                        {it}
                                                                    </Typography>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        dataItem.images &&
                                                        dataItem.images.map((item) => {
                                                            return (
                                                                <>
                                                                    <ImageContainer key={idx} image={item} />
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        dataItem.tables &&
                                                        <DataTableContainer key={idx} tableData={dataItem.tables} />
                                                    }
                                                    {
                                                        dataItem.url &&
                                                        <DownloadContainer key={idx} item={dataItem} />
                                                    }
                                                </>
                                            }
                                        </>
                                    )
                                })
                            }
                        </>
                    )
                })
            }
        </Grid>
    )
}

export default BaseContentContainer