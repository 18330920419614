import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const info = 'CAPI DOCS'
const useStyles = makeStyles({
    infoCardContainer: {
        justifyContent: 'center',
        height: '64px',        
        background : 'linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%)',
        cursor: 'pointer'
    },
    infoCardTitle: {
        marginTop: '10%',
        marginLeft: '118px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1px',
        color: '#ffffffff'
      }
})

function handleReload(){
    localStorage.removeItem('searchData');
    localStorage.removeItem('searchClicked');
    localStorage.removeItem('searchText');
    localStorage.removeItem('cardData');    
    window.location.reload();
}

const DocumentInfoCard = (props) => {
    const classes = useStyles()
    return (
        <Grid onClick={handleReload} className={classes.infoCardContainer}>
            <div style={{position: 'absolute', marginTop: '20px', marginLeft: '5px', padding: '3px'}}>
                <img alt={info} src={process.env.PUBLIC_URL + "favicons/logo-for-info.png" } width={107}/>
            </div>
            <Typography noWrap className={classes.infoCardTitle}>
                {info}
            </Typography>
        </Grid>
    )
}

export default DocumentInfoCard