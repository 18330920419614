import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import axios from 'axios'
import ContainerSpinner from '../../ContainerSpinner/index'
import CodeSnippet from './CodeSnippet'

const CODE_DIR_NAME_PREFIX = './assets/data/code/'
const ErrorDisp = 'Code snippet not found ...'

const CLikeCodeSnippet = (props) => {
  const { CSnippet, lang } = props
  const [snippet, setSnippet] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    let promise = [
      axios.get(CODE_DIR_NAME_PREFIX + CSnippet)
    ]
    Promise.all(promise).then(response => {
      setSnippet(
        response.map((item) => {
          return item.data
        })
      )
      setLoading(false)
    }, error => {
      setSnippet(
        ErrorDisp
      )
    })
  }, [CSnippet])

  return (
    <>
      {
        !isLoading
          ?
          <CodeSnippet snippet={snippet} lang={lang} />
          :
          <ContainerSpinner />
      }
    </>
  )
}

const JavaCodeSnippet = (props) => {
  const { JSnippet, lang } = props
  const [snippet, setSnippet] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    let promise = [
      axios.get(CODE_DIR_NAME_PREFIX + JSnippet)
    ]
    Promise.all(promise).then(response => {
      setSnippet(
        response.map((item) => {
          return item.data
        })
      )
      setLoading(false)
    }, error => {
      setSnippet(
        ErrorDisp
      )
    })
  }, [JSnippet])

  return (
    <>
      {
        !isLoading
          ?
          <CodeSnippet snippet={snippet} lang={lang} />
          :
          <ContainerSpinner />
      }
    </>
  )
}


function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: '30%',
    maxWidth: 'calc(100%)',
    backgroundColor: '#2d2d2d',
    borderRadius: '10px'
  },
}));


const CodeSnippetsContainer = (props) => {
  const { codeItems } = props
  const classes = useStyles()
  const [value, setValue] = React.useState('cpp')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid className={classes.root}>
      <AppBar position="static" style={{ background: '#28393e', borderRadius: '10px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
          <Tab
            value="cpp"
            label="C++"
            wrapped
            {...a11yProps('cpp')}
          />
          <Tab value="java" label="JAVA" {...a11yProps('java')} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index="cpp">
        <CLikeCodeSnippet CSnippet={codeItems.cpp} lang={value} />
      </TabPanel>
      <TabPanel value={value} index="java">
        <JavaCodeSnippet JSnippet={codeItems.java} lang={value} />
      </TabPanel>
    </Grid>
  );
}

export default CodeSnippetsContainer