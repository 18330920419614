import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const IMAGE_DIR_NAME_PREFIX = './assets'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
        margin: '30px auto'
    },
    media: {
        width: '100%',
        padding: '3%'
    },
    imageinfo: {
        fontStyle: 'normal',
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '1px',
        color: '#424242'
    }
}));

const MediaCard = (props) => {
    const classes = useStyles()
    const { image } = props
    return (
        <>
            <Card className={classes.root}>
                <CardActionArea>
                    <Grid>
                        <CardMedia>
                            <img className={classes.media} alt={image.imageName} src={process.env.PUBLIC_URL + IMAGE_DIR_NAME_PREFIX + image.imageURL} title={image.imageName} />
                        </CardMedia>
                    </Grid>
                    <CardContent>
                        <Typography variant="body2" component="p" noWrap className={classes.imageinfo}>
                            {image.imageName && image.imageName !== '' ? image.imageName : 'Figure: '}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <br />
            <br />
        </>
    );
}

export default MediaCard
