import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CopyBlock, obsidian } from "react-code-blocks"

const useStyles = makeStyles({
    codeText: {
        fontSize: '12px',
        code: {
            fontSize: '12px'
        }
    },
    fontClass: {
        fontSize: '13px'
    }
});

const CodeSnippet = (props) => {
    const {snippet, lang} = props
    const classes = useStyles()

    return (
        <div className={classes.fontClass}>
        <CopyBlock
          language={lang}
          text={snippet}
          showLineNumbers={false}
          theme={obsidian}
          wrapLines={true}
          codeBlock={true}
        />
        </div>
    )
}

export default CodeSnippet