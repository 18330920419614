import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import RecursiveSideBarDataProvider from './RecursiveSideBarDataProvider'
import Grid from '@material-ui/core/Grid'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignContent: 'right'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
        background: '#fff'
    }
}));

const Sidebar = (props) => {
    const { handleDrawerToggle, mobileOpen, window, data, updateMethod, activeTab, handleSearch } = props
    const classes = useStyles()
    const theme = useTheme()
    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <Grid className={classes.container}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <RecursiveSideBarDataProvider sidebarItems={data} updateCurrentSelection={updateMethod} activeTab={activeTab} handleSearch={handleSearch} />
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <RecursiveSideBarDataProvider sidebarItems={data} updateCurrentSelection={updateMethod} activeTab={activeTab} handleSearch={handleSearch} />
                    </Drawer>
                </Hidden>
            </nav>
        </Grid>
    )
}

export default Sidebar