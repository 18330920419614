import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles(theme => ({
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  },
  avatar: {
    height:'28px',
    width:'30px',
  }
}));

const DownloadContainer = (props) => {
  const { item } = props;
  const classes = useStyles();
  const download = (e, file) => {
    e.preventDefault();
    setTimeout(() => {
      window.location.href = file;
    }, 100);
  }

  return (
    <>
      <Grid>
        <Typography variant="subtitle1" className={classes.wrapIcon}>
          <Chip avatar={<Avatar>Java</Avatar>} label="Download Java code" onClick={(e) => download(e, item.url.java_url)} />
          <Chip avatar={<Avatar>C++</Avatar>} label="Download C++ code" onClick={(e) => download(e, item.url.c_url)} />
        </Typography>
      </Grid>
    </>
  )
}

export default DownloadContainer