import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
    table: {
        width: '650px',
    },
    tableHeader: {
        fontStyle: 'normal',
        lineHeight: '16px',
        letterSpacing: '1px',
        color: '#424242',
        fontWeight: 'bold',
        fontSize: '15px'
    }
});

const DataTableContainer = (props) => {
    const { tableData } = props
    const classes = useStyles()
    return (
        <>
            <Grid container justify='flex-start'>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead style={{ background: '#E6E6E6' }}>
                            <TableRow>
                                {
                                    tableData[0].tableHeader &&
                                    tableData[0].tableHeader.map(item => {
                                        return (
                                            <>
                                                <TableCell align="justify">
                                                    <Typography className={classes.tableHeader}>
                                                        {item}
                                                    </Typography>
                                                </TableCell>
                                            </>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData[0].tableRows.map((row) => {
                                return (
                                    <>
                                        <TableRow>
                                            {
                                                row.tableRow.map((rdata) => {
                                                    return (
                                                        <TableCell align="justify" scope="rdata">
                                                            {
                                                                rdata && Array.isArray(rdata)
                                                                    ?
                                                                    (
                                                                        rdata.map((item, index) => {
                                                                            return (
                                                                                <Typography key={index} style={{fontSize: '13px'}}>
                                                                                    {item}
                                                                                </Typography>
                                                                            )

                                                                        })
                                                                    )
                                                                    :
                                                                    (
                                                                        rdata
                                                                    )
                                                            }
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <br />
        </>
    )
}

export default DataTableContainer